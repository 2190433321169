import React from 'react';

const MailContacts = () => {
    return (
        <>
            <h5>Send Mail (Mockup)</h5>
            <p>This is not implemented yet.</p>
        </>
    );
};

export default MailContacts;