import React from 'react';

const PrintContacts = () => {
    return (
        <>
            <h5>Print Address (Mockup)</h5>
            <p>This is not implemented yet.</p>
        </>
    );
};

export default PrintContacts;